<template>
  <van-sticky :offset-top="30" teleport="body">
    <van-form @submit="onSubmit">
      <van-cell-group title="上报信息" icon="friends-o" inset>
        <van-field
            v-model="id"
            name="id"
            v-show="false"
        ></van-field>


        <Picker
            name="exceptionType"
            label="选择异常类型"
            placeholder="选择异常类型"
            displayField="name"
            v-model:value="this.$data['exceptionType']"
            :select-first="false"
            :defConfig="{
                     // portName: 'tms.basic.nodeInfo.getList',
                     portName: 'system.foundation.Dic.getChildDicsByCode',
                     data: {'code':'EXCEPTION_TYPE'}
                 }"
            required
            :rules="[{ required: true, message: '请选择选择异常类型' }]"
        ></Picker>


        <van-field v-model="remark"
                   autosize
                   rows="3"
                   type="textarea"
                   label="异常原因" placeholder="请输入异常原因"  :required=true />




        <van-field name="attachinfo" label="上传照片(客户凭证)" :required=true>
          <template  #input>
              <van-uploader v-model="this.dataList['attachinfo']"
                           :before-read="beforeRead"
                           :after-read="afterRead"
                           max-count="3">
              </van-uploader>
          </template>
      </van-field>
            <!--            表单参数                    -->

<!--          </template>-->
      </van-cell-group>
      <div style="margin: 4.26667vw; position: relative">
        <van-button round block type="primary" native-type="submit">保存</van-button>
      </div>
    </van-form>

  </van-sticky>

</template>



<script  charset="utf-8" type="text/javascript">




import ListCard from "../../components/ListCard";
import _ from "lodash";
import Picker from "../../components/Picker";
import GridList from "../../components/GridList";
import SelectMaterial from "./SelectMaterial";
import EditMaterialPopup from "./EditMaterialPopup";
import DatePicker from "@/components/DatePicker.vue";
import DatetimePicker from "@/components/DatetimePicker.vue";


function onComplete (data) {
  alert(JSON.stringify(data));
}

function onError (data) {
  alert(JSON.stringify(data));
}

var photoJdk = {
  isSDKReady: false,
    init: function (assas) {
    var option = {
      portName: 'system.security.Security.getJsApiSignModel',
      data: {
        url: location.href
      },
      successCallback: function (data) {
        var jsApiSign = data.data.jsApiSign;
        photoJdk.initWechatJsConfig(jsApiSign);
      }
    };
    assas.$sapi.callPort(option)
  },
  //初始化微信js配置
  initWechatJsConfig: function (jsApiSign) {
    var me = this;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: jsApiSign.appID, // 必填，公众号的唯一标识
      timestamp: jsApiSign.timestamp, // 必填，生成签名的时间戳
      nonceStr: jsApiSign.nonceStr, // 必填，生成签名的随机串
      signature: jsApiSign.signature,// 必填，签名
      jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
    });
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
      // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
      // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      me.isSDKReady = true;
      console.log('JsApiJDK 签名验证正常:');
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，
      // 也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('JsApiJDK 签名验证异常:' + JSON.stringify(res));
    });
    //wechat jdk 注册
  },
  //开始拍照 callback(serverId),serverId为多媒体ID
  startCapture: function (callback) {
    var me = this;
    if (!photoJdk.isSDKReady) {
      console.log("拍照SDK正在初始化，稍后重试");
      console.log(1)
      callback("未成功获取")
      return;
    }
    wx.getLocation({
      type: 'gcj02',
      success (res) {
        const latitude = res.latitude
        const longitude = res.longitude
        const speed = res.speed
        const accuracy = res.accuracy
        callback(latitude+","+longitude)
      }
    })
  }
}
export default {
  name: "ExceptionReserveMaterial2",
  components: {Picker},
  // components: {DatetimePicker, DatePicker},

  created() {
    this.loadData();
  },
  props: {
    reserveID: {
      default: '',
      type: String
    },
    isLoad: {
      default: true,
      type: Boolean
    },
    isHazardous: {
      default: false,
      type: Boolean
    },
    back: {
      default: null,
      type: Function
    },

    // nodeInfoId: {
    //   default: '',
    //   type: String
    // },
    // nodeId: {
    //   default: '',
    //   type: String
    // },
  },
  data() {
    return {
      id: '',
      showEdit: false,
      btnDisabled: false,
      data: [],
      dataList: '',
      fieldList: [],
      lnglat:'',
      remark:'',
      exceptionType:''

    };
  },

  watch: {
    showEdit: function (val) {
      if (val) {
        return;
      }
      this.$refs.editMaterial.$data.item = {
        id: '',
        'reserve.id': this.reserveID,
        'material.id': '',
        'material.name': '',
      };
    }
  },

  methods: {
    loadData() {
       photoJdk.init(this)

      //  function getLocation() {
      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(
      //         function success(position) {
      //           console.log('经度：'+ position.coords.latitude)
      //           console.log('纬度：'+ position.coords.longitude)
      //         },
      //         function fail(error) {
      //           switch (error.code) {
      //             case error.PERMISSION_DENIED:
      //               console.log('用户拒绝对获取地理位置的请求。')
      //               break;
      //             case error.POSITION_UNAVAILABLE:
      //               console.log('位置信息是不可用的。')
      //               break;
      //             case error.TIMEOUT:
      //               console.log('请求用户地理位置超时。')
      //               break;
      //             case error.UNKNOWN_ERROR:
      //               console.log('未知错误。')
      //               break;
      //           }
      //         },
      //         { enableHighAcuracy: false }
      //     );
      //   } else {
      //     console.log('Geolocation is not supported by this browser.')
      //   }
      // }
      // getLocation()

      var me = this;
      me.btnDisabled = true;
      me.showDialog = false;
      var option = {
        portName: 'tms.transport.dispatch.TmsDispatchOrderNodeReport.getEntityVapp',
        data: {"custom.id": this.reserveID},
        // data: {"custom.id": this.reserveID, "nodeInfo.id": this.nodeInfoId},
        needLoading: false,
        successCallback: function (data) {
          var entities = data.entities;
          if (!_.isEmpty(entities)) {
            var entity = entities[0];
            var _data = me.$data;
            console.log(entity)
            _data.dataList = entity;
            _data.fieldList = entity.fieldList
            _data.id = entity.id;
            // console.log(_data['dataList']['attachinfo'][0])
            // _data['dataList']['attachinfo'][0].url='https://fastly.jsdelivr.net/npm/@vant/assets/leaf.jpeg'
            me.btnDisabled = false;
          }
        }
      }
      this.$sapi.callPort(option);



    },
    onSubmit(values) {
      var me = this;
      photoJdk.startCapture(function (lnglat){
        values['dispatchOrder.id'] = me['id'];
        values['lnglat']=lnglat
        values['id'] = me.id
        values['remark'] = me.remark
        if (me.id === undefined)
          values['id'] = ""
        // values['node.id'] = me.nodeId
        // values['nodeInfo.id'] = me.nodeInfoId
        values['customId'] = me.reserveID
        var option = {
          portName: 'tms.transport.dispatch.TmsExceptionInfoPort.saveEntity',
          data: values,
          needLoading: false,
          successCallback: (data) => {
            me.$notify({type: 'success', message: '保存成功'})
          },errorCallback:()=>{
            me.$notify({type: 'danger', message: '保存失败'})
          }
        };
        me.btnDisabled = true;
        try {
          me.$sapi.callPort(option);
          me.$router.replace("/Exception2");

        } finally {
          me.btnDisabled = false;
        }
      })

    },
    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true)
    },

    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onAdd: function () {
    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.ReserveMaterial.delReserveMaterial',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    addMaterial() {
      this.showEdit = true;
      this.$refs.editMaterial.$data.item = {
        id: '',
        'reserve.id': this.reserveID,
        'material.id': '',
        'material.name': '',
        qty: 0,
        orderQty: 0,
        orderCode: '',
        unit: 'kg',
        'platform.id': '',
        pointName: '',
        principal: '',
        principalName: '',
        pointPhone: '',
        supplier: '',
        supplierNo: '',
        isSDKReady: false,
      }
    },
    editMaterial(id) {
      this.showEdit = true;
      var option = {
        portName: 'osp.car.ReserveMaterial.getReserveMaterial',
        data: {id: id},
        needLoading: false,
        successCallback: (data) => {
          var entity = data.entities[0];
          this.$refs.editMaterial.$data.isPRo = entity['material.name'] === '项目物资' ? 'true' : 'false';
          this.$refs.editMaterial.$data.item = {
            id: entity.id,
            'reserve.id': entity['reserve.id'],
            'material.id': entity['material.id'],
            'material.name': entity['material.name'],
            qty: entity['qty'],
            orderQty: 0,
            orderCode: '',
            unit: entity['unit'],
            'point.id': entity['point.id'],
            principal: entity['principal'],
            principalName: entity['principalName'],
            supplier: entity['supplier'],
            supplierNo: entity['supplierNo']
          };
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      }
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    confirmEdit(value) {
      var option = {
        portName: 'osp.car.ReserveMaterial.saveReserveMaterials',
        data: value,
        needLoading: false,
        successCallback: () => {
          this.$refs.grid.onRefresh();
        }
      }
      this.$sapi.callPort(option);
    },
    onSubmitReserve() {
      var option = {
        portName: 'osp.car.VehicleReserve.submitVehicleReserve',
        data: {ids: [this.reserveID]},
        needLoading: false,
        successCallback: (data) => {
          this.$notify({type: 'success', message: '提交成功'});
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    pageChangeToOne() {
      if (this.back) {
        this.back();
      }
    },

  }
}
</script>

<style scoped>
.list_cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 3px 12px 3px 12px;
  overflow: hidden;
  color: var(--van-cell-text-color);
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
  background: var(--van-cell-background-color);
}
</style>
